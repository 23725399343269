@import
"src/main/scss/mixins/media-query",
"src/main/scss/mixins/state-indicator",
"src/main/scss/functions/find-color-invert",
'flatpickr/dist/flatpickr.min';

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid var(--color-accent-bg);
  -webkit-text-fill-color: var(--color-accent-bg);
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* Custom inputs */
.custom-control {
  position: relative;
  display: inline-flex;

  &__label {
    font-weight: normal;
  }
}

[type="radio"] {
  border-radius: 50%;

  &:before {
    border-radius: 50%;
    height: calc(100% - 6px);
    width: calc(100% - 6px);
  }
}

[type="checkbox"] {
  border-radius: 3px;

  &:checked {
    &:before {
      content: '\ed71'; // checkmark
      font-family: var(--font-icon);
      width: 100%;
      height: 100%;
      background: var(--color-bg);
    }
  }
}

[type="checkbox"],
[type="radio"] {
  position: relative;
  appearance: none;
  border: 2px solid var(--gray-6);
  color: #fff;
  height: 20px;
  width: 20px;
  margin-right: var(--space-sm);
  flex-shrink: 0;
  outline: none;
  @include state-indicator();

  // Active state
  &:before {
    content: ' ';
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    background: var(--color-text);
    transition: transform .15s linear;
    transform: translate(-50%,-50%) scale(0);
    opacity: 0;
    will-change: opacity, transform;
  }



  &:checked {
    border-color: var(--color-text);

    &:before {
      opacity: 1;
      transform: translate(-50%,-50%) scale(1);
    }

    // &:focus {
    //   &:after {
    //     opacity: .15;
    //   }
    // }
  }
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;

  &:not(:last-child){
    margin-bottom: var(--space-xl);
  }
}

legend {
  padding-bottom: var(--space-xs);
  margin-bottom: var(--space-lg);
  font-weight: var(--type-weight-heading);
  font-size: var(--text-sm);
  border-bottom: 1px solid var(--color-border);
  width: 100%;
  text-transform: uppercase;
  color: var(--color-primary);
}

label {
  font-size: var(--text-xs);
  font-weight: var(--type-weight-meta);
  display: inline-block;
  margin-bottom: var(--space-xxs);

}

label,
legend {
  em {
    font-size: var(--text-sm);
    color: var(--color-text-meta);
    text-transform: none;
  }
}

.form {
  &__section {
    //background: #fff;
    padding: var(--space-lg) var(--space-md);

    &:not(:last-child){
      margin-bottom: var(--space-md);
    }

    &.is{
      &--box-shadow {
        box-shadow: 2px 2px 15px -9px rgba(0,0,0,.65)
      }
      &--alpha {
        background: rgba(255,255,255,.7);
      }
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__col {
    display: flex;

    &:not(:last-child){
      margin-right: var(--space-md);
    }
  }

  &__group {
    position: relative;
    letter-spacing: .04em;
    line-height: 1.25rem;

    &:not(:last-child){
      margin-bottom: var(--space-sm);
    }
  }

  &__item {
    &:not(:last-child){
      margin-bottom: var(--space-sm);
    }
  }

  &__control {
    display: block;
    width: 100%;
    border: 1px solid var(--color-border);
    border-radius: 3px;
    font-size: var(--text-sm);
    padding: var(--space-xs);
    transition: none;
    // Overrides the 'min-width' with the <size> attribute.
    // This is e.g. useful for the postal code or creditcard number!
    &:not([size]){
      min-width: 240px;
    }

    &.is {
      &--auto-width {
        width: auto;
      }
    }
  }

  &__label-title {
    font-size: var(--text-sm);
    font-weight: var(--type-weight-meta);
    margin:var(--space-md) 0 var(--space-xs) 0;
    //color: var(--color-primary);
  }

  // Floated Label's
  &__floated-label {
    position: relative;
    font-size: inherit;
    font-weight: inherit;
    width: 100%;
    margin-top: 10px;

    .form__control {
      border-top: none;
      border-left: none;
      border-right: none;
      padding-left: 0;
      padding-right: 0;

      &:focus {
        box-shadow: none;
      }
    }

    &.is {
      &--active {
        .form__floated-text {
          font-weight: var(--type-weight-meta);
          top: 0;
          transform: translate(0, -60%) scale(.75);
          color: rgba(0,0,0,.5);
        }
      }

      &--filled {
        .form {
          &__floated-text {

          }
        }
      }
    }
  }

  &__floated-text {
    position: absolute;
    top: 50%;
    left: 0;
    transform-origin: top left;
    transform: translate(0, -50%) scale(1);
    transition: all .1s ease-in-out;
    background: #fff;
    font-size: var(--text-sm);
    color: var(--color-text-meta);
    //font-weight: var(--type-weight-meta);
    //padding: 0 .5em;
  }

  &__message {
    margin-top: var(--space-xs);
    font-size: var(--text-xs);
    font-weight: var(--type-weight-meta);

    &.is {
      &--error {
        color: var(--color-error);
      }
    }
  }
}

.input-group {
  position: relative;

  &__append {
    position: absolute;
    right: 0;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
    padding: 5px;
    color: var(--color-text-meta);
  }
}

/*
  Field status and validation
*/
input,
textarea,
select {
  background-color: var(--form-element-bg);
  border: 1px solid var(--form-element-border);
  color: var(--color-accent-light);

  &:focus {
    box-shadow: 0 0 15px -11px var(--color-accent);
    outline: none;
    border-color: var(--color-accent);
  }

  &.is {
    &--error {
      border-color: var(--color-error);

      &:focus {
        box-shadow: 0 0 15px -2px var(--color-error);
      }
    }
    &--valid {
      border-color: var(--color-valid);

      &:focus {
        box-shadow: 0 0 15px -2px var(--color-valid);
      }
    }
  }
}
