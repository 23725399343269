/*
Hover adds a gray background in the: after element.
This is for usability.

Usage:
@include state-indicator();
*/

@mixin state-indicator() {
  &:after {
    content: '';
    pointer-events: none;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: calc(100% + 1.25em);
    height: calc(100% + 1.25em);
    border-radius: 50%;
    background: #fff;
    opacity: 0;
    transform: translate(-50%,-50%);
    transition: opacity .15s linear;
  }

  &:hover {
    cursor: pointer;

    &:after {
      opacity: .075;
    }
  }

  &:focus,
  &:active {
    &:after {
      opacity: .15;
    }
  }

  &:checked {
    &:focus {
      &:after {
        opacity: .15;
      }
    }
  }
}
